export const data = {
  experience: [
    {
      title: "Consultant",
      subTitle: "Miami,Fl",
      description: "User Experience,Viusal Design",
    },
    {
      title: "Senior Consultant",
      subTitle: "London,UK",
      description: "Web devlopment,Databases, Backend devlopment",
    },
    {
      title: "Project Manager",
      subTitle: "Bangalore,INDIA",
      description: "Creative Direction Project Managment,Team Leading",
    },
  ],
  education: [
    {
      title: "Secondry",
      subTitle: "RAIS School of higher education",
      description: "10th Grade",
    },
    {
      title: "Higher Secondry",
      subTitle: "RAIS School of higher education",
      description: "12th Grade in Science",
    },
    {
      title: "Bsc.(CS)",
      subTitle: "B.N.N College",
      description: "Bachelor of technology in computer science",
    },
  ],
};
